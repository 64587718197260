import React from 'react'

const WhyChoose = () => {


  return (
    <section className="choosedesire ychoose pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2">Coinsclone <span className="bluecolor">The Best Ethereum</span> Token Development Company</h2>
            <p className="pharagraph head mb-2">Our Ethereum Token development services is the finest and top-notch service that you find in the crypto industry. We offer a single package in which you can find all that you need to develop a well-performing Ethereum-based Token with elite features.
            </p>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/ethereum/Universal applicability.svg" alt="One-stop solution" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                One-stop solution
                </h3>
                <p className="pharagraph">
                Being a reputed Ethereum token development company in the industry, we offer end-to-end Ethereum token development services for all crypto aspirants who are willing to launch secure Ethereum-based tokens at a moderate cost. 
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/ethereum/Ethereum blockchain.svg" alt="Easy deployment" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Easy deployment
                </h3>
                <p className="pharagraph">
                Once we have developed your Ethereum token, we will help you deploy it on top of the powerful Ethereum blockchain network and in your preferred digital wallet.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/ethereum/Easy deployment.svg" alt="24*7 Technical support" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                24*7 Technical support
                </h3>
                <p className="pharagraph">
                We provide ongoing support and in case you want to extend the capabilities and the validations of your Ethereum token, you can always contact us. We are always here to assist and support you at any cost.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/ethereum/Global reach.svg" alt="Experienced Team" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Experienced Team
                </h3>
                <p className="pharagraph">
                We have more than 50+ seasoned blockchain developers and highly skilled UI/UX designers to handle your project and create efficient Ethereum-based tokens according to the clientele requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/ethereum/Further expansion.svg" alt="On-time project delivery" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                On-time project delivery
                </h3>
                <p className="pharagraph">
                Once the client has quoted the requirements, our token development will develop and deploy tokens on the Ethereum blockchain within the desired time.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/ethereum/Complete ownership.svg" alt="Universal applicability" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                Universal applicability
                </h3>
                <p className="pharagraph">
                By getting our services, you can convert any valuable asset into an Ethereum-based token, and we can help you in this regard. Also, you can create utility-type Ethereum tokens as per your needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChoose